.move {
  animation-name: popup;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
}

@keyframes popup {
  0% {
    transform: scale(1);
  }
  10% {
    transform: scale(1.5);
  }
  20% {
    transform: scale(1);
  }
  30% {
    transform: scale(1.4);
  }
  40% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
  60% {
    transform: scale(1);
  }
  70% {
    transform: scale(1.2);
  }
  80% {
    transform: scale(1);
  }
  90% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
