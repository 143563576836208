.horizontalCenter {
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.verticalCenter {
  width: 100%;
  display: inline-flex;
  justify-content: center;
  flex-direction: column;
}
